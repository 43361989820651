import React, { useEffect, useState } from "react";
import DataTable2 from '../../context/components/DataTable2';
import AuthContext from "../../context/auth/AuthContext";
import axiosPrivate from '../../utils/http/axiosPrivate';
import { Link } from "react-router-dom/dist";
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from "react-icons/fa";
function Esi(){

    const[esistate,setEsiState] = useState([]);

  const headers = [
    { name: "Esi Employee Percent", field: "esi_employee_percent", sortable: true, classKey: "" },
    { name: "Esi Employer Percent", field: "esi_employer_percent", sortable: true, classKey: "" },
    { name: "Esi Employee Fixed Amount", field: "esi_employee_fixed_amount", sortable: true, classKey: "" },
    { name: "Esi Employer Fixed Amount", field: "esi_employer_fixed_amount", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ['name'];
 useEffect(()=>{
    const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate.get('esi')
      .then((esi)=>{
        const esiData = esi?.data.data?.map((value,key)=>{
            let buttons= [];

					if (true)
                    buttons.push(
                        <Link
                        key={`editButton##${key}`}
                        type="button"
                        to={`/admin/esi/edit/${value.id}`}
                        className="btn btn-icon"
                        title="Edit"
                      >
                        <BiEdit style={{ color: "green" }} size={18} />
                      </Link>
                    );

					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
        });
        setEsiState(esiData)

      }).catch((error) => {
        if (error.response) {
          console.log(error.response)
            //response status is an error code
            //showErrorToast('Internal server error');
        }  
    });
}, []);


    return(
        <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <ul className="nav nav-tabs page-header-tab text-dark">
          <li className="nav-item navSpace">
              <div className="card-header headerSpace">
          <strong className="top-left-heading"> ESI</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="route-heading item mx-2">
              {/* <Link to="/admin" className="route-heading item mx-2"> */}
                Dashboard
              {/* </Link> */}
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              ESI
            </li>
          </ol>
        </div>
            </li>
          </ul>

          {/* right side add button */}
          <div className="header-action">
            {/* {authPermissions.includes("Category-Create") ? ( */}
              {/* <Link to="/admin/esi/add" className="text-decoration-none">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="fe fe-plus mr-2" />
                  Add
                </button>
              </Link> */}
            {/* ) : null} */}
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="category-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={esistate}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    )
}

export default Esi;
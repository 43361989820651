import React from "react";
import { useForm } from "react-hook-form";
import axiosPrivate from "../../utils/http/axiosPrivate";
import { useNavigate } from "react-router-dom/dist";

function AddPf() {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        axiosPrivate.post('/pf-create',data)
        .then((res)=>{
            navigate('/admin/pf');
        })
        .catch((error) => {
            if (error.response) {
                // showErrorToast('Internal server error');

                if (error.response.status === 422) {
                    
                }
            }
        })
    }
  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Add pf Details
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Employee pf Percent:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="emp_code"
                            name="pf_employee_percent"
                            type="number"
                            className="form-control"
                            placeholder="Employee pf Percent"
                            step="0.01"
                            {...register("pf_employee_percent", {
                              required: true,
                            })}
                          />
                          {errors.pf_employee_percent && (
                            <p style={{color:'red',fontSize:'smaller'}}>Employee pf Percent Required</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Employer pf Percent:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="emp_code"
                            name="pf_employer_percent"
                            type="number"
                            className="form-control"
                            placeholder="Employer pf Percent"
                            step="0.01"
                            {...register("pf_employer_percent", {
                              required: true,
                            })}
                          />
                          {errors.pf_employer_percent && (
                            <p style={{color:'red',fontSize:'smaller'}}>Employer pf Percent Required</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Employee pf Fixed Amount:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="pf_employee_fixed_amount"
                            name="pf_employee_fixed_amount"
                            type="number"
                            className="form-control"
                            placeholder="Employee pf Fixed Amount"
                            {...register("pf_employee_fixed_amount", {
                              required: true,
                            })}
                          />
                          {errors.pf_employee_fixed_amount && (
                            <p style={{color:'red',fontSize:'smaller'}}>Employee pf Fixed Amount Required</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Employer pf Fixed Amount:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="pf_employer_fixed_amount"
                            name="emp_code"
                            type="number"
                            className="form-control"
                            placeholder="Employer pf Fixed Amount"
                            {...register("pf_employer_fixed_amount", {
                              required: true,
                            })}
                          />
                          {errors.pf_employer_fixed_amount && (
                            <p style={{color:'red',fontSize:'smaller'}}>Employer pf Fixed Amount Required</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate(-1)}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPf;

import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import "./main.css";

const Main = (props) => {
  return (
    <Fragment>
      <div className="font-montserrat">
        <div id="main_content">
          <div className="">
            <div>
              <SideBar />
            </div>
            <div className="page">
              <Header />
              <div className="">
                <Outlet />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Main;

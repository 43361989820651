import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import DataTable2 from "../../../context/components/DataTable2/index";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import AuthContext from "../../../context/auth/AuthContext";

const CompanyForm = ({ authPermission }) => {
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const [companytate, setcompanytate] = useState([]);
  const [refreshTable,setRefreshTable]=useState(false)

  const headers = [
    {
      name: " COMPANY NAME",
      field: "name",
      sortable: true,
      classKey: "",
    },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    {
      name: "CONTACT NUMBER",
      field: "mobile_no",
      sortable: true,
      classKey: "",
    },
    { name: "TYPE", field: "type", sortable: true, classKey: "" },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name","email","type"];
  // get All nmStaff list
  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("company", options)
      .then((company) => {
        const companyData = company?.data?.data?.map((value, key) => {
          let buttons = [];

          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/company/edit/${value.id}`}
              className="btn btn-icon"
              title="Edit"
            >
              <i className="fa fa-edit" style={{color:'green',fontSize:'18px'}}></i>
            </Link>
          );

          buttons.push(
            <button
              key="deleteButton##1"
              type="button"
              data-id={value.id}
              onClick={() => companyDeleteHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
            >
              <i className="fa fa-trash-o " style={{color:'red',fontSize:'18px'}}></i>
            </button>
          );

          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setcompanytate(companyData);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
      });
  }, [refreshTable]);

  

  // delete  user function
  const companyDeleteHandler =(id) => {
    axiosPrivate
      .delete(`company/delete/${id}`)
      .then(async (response) => {
        if (response.data.code === 200) {
          
          showSuccessToast("User Deleted Successfully!");
          setRefreshTable(true)
          
        }
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
      });
  };


 
  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <ul className="nav nav-tabs page-header-tab text-dark">
          <li className="nav-item navSpace">
          <div className="card-header headerSpace">
                <div className="breadcrumb-area">
                  {/* header heading */}
                  <h1
                    className="card-title text-dark"
                    style={{ fontWeight: "700", fontSize: "18px" }}
                  >
                    Company
                  </h1>

                  {/* header routing */}
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item mx-2">
                      
                        Dashboard
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Company list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>

          {/* right side add button */}
          <div className="header-action">
            {/* {authPermissions.includes('Staff-Create') ? ( */}
            <Link to="/admin/companyform/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
            {/* ) : null} */}
          </div>
        </div>
      </div>

      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={companytate}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyForm;

import React, { useEffect, useMemo, useState } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { Pagination, Search, TableHeader } from "../DataTable";
import Header from "../Header";

const DataTable2 = ({ lists, headers, searchItems }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filterListLengths = [10, 25, 50, 100];
  const [emp_code, setEmp_code] = useState(null);
  const [headers2, setHeaders2] = useState([
    { name: "In Date", field: "in_date", sortable: false, classKey: "" },
    { name: "In Time", field: "in_time", sortable: false, classKey: "" },
    { name: "Out Date", field: "out_date", sortable: false, classKey: "" },
    { name: "Out_Time", field: "out_time", sortable: false, classKey: "" },
    { name: "Status", field: "status", sortable: false, classKey: "" },
  ]);

  useEffect(() => {
    setComments(lists);
    setIsLoading(false); // Assuming data loading is complete here
  }, [lists]);

  const listData = useMemo(() => {
    let computedData = comments;

    if (search && searchItems?.length > 0) {
      computedData = computedData?.filter((filterData) =>
        searchItems?.some((field) =>
          filterData[field]?.toLowerCase()?.includes(search?.toLowerCase())
        )
      );
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    const startIndex = (currentPage - 1) * itemsPerPage;

    const endIndex = startIndex + itemsPerPage;

    return computedData.slice(startIndex, endIndex);
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  // const changeItemsPerPage = (e) => {
  //   setItemsPerPage(e.target.value);
  // };
  const changeItemsPerPage = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  return (
    <div className="row w-100">
      <div className="col mb-3 text-center">
        <div className="row mb-4">
          <div className="col-md-6 col-sm-6">
            <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            />
          </div>

          <div className="col-sm-12 col-md-6 d-flex flex-row-reverse ">
            <div className="float-left ml-3">
              <label className="mr-2">Show</label>
              <select
                name="list_length"
                className="form-select"
                onChange={changeItemsPerPage}
              >
                {filterListLengths.map((value, key) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <span className="ml-2">entries</span>
            </div>
          </div>
        </div>

        <table className="table table-hover table-fixed">
          <TableHeader
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {listData.map((comment, idx) => (
              <>
                {comment?.attendance?.length > 0 ? (
                  <>
                    <tr
                      key={comment.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (emp_code === comment?.name) {
                          setEmp_code(null);
                        } else {
                          setEmp_code(comment?.name);
                        }
                      }}
                    >
                      <td style={{ textAlign: 'left'}}>{(idx + ((currentPage-1) * itemsPerPage)) + 1}</td>
                      {/* Assuming a unique id field */}
                      {headers.map((col, colIdx) => (
                        <td
                          key={`${comment.id}-${colIdx}`}
                          style={{ textAlign: "left" }}
                        >
                          {col.field !== "image" ? (
                            comment[col.field]
                          ) : (
                            <img
                              src={comment[col.field]}
                              alt={`Image ${col.field}`}
                              style={{ width: "70px", height: "50px" }}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                    {emp_code === comment?.name ? (
                      <tr>
                        <td colSpan={12}>
                          <table style={{ width: "100%" }}>
                            <TableHeader
                              headers={headers2}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            />
                            <tbody>
                              {comment?.attendance?.map((comment, idx) => (
                                <tr key={comment.id}>
                                  {" "}
                                  {/* Assuming a unique id field */}
                                  {headers2.map((col, colIdx) => (
                                    <td
                                      key={`${comment.id}-${colIdx}`}
                                      style={{ textAlign: "left" }}
                                    >
                                      {col.field !== "image" ? (
                                        comment[col.field]
                                      ) : (
                                        <img
                                          src={comment[col.field]}
                                          alt={`Image ${col.field}`}
                                          style={{
                                            width: "70px",
                                            height: "50px",
                                          }}
                                        />
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : (
                  <tr key={comment.id}>
                    <td style={{ textAlign: 'left'}}>{(idx + ((currentPage-1) * itemsPerPage)) + 1}</td>
                    {/* Assuming a unique id field */}
                    {headers.map((col, colIdx) => (
                      <td
                        key={`${comment.id}-${colIdx}`}
                        style={{ textAlign: "left" }}
                      >
                        {col.field !== "image" ? (
                          comment[col.field]
                        ) : (
                          <img
                            src={comment[col.field]}
                            alt={`Image ${col.field}`}
                            style={{ width: "70px", height: "50px" }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>

        <div className="col-sm-12 col-md-12 d-flex justify-content-end align-items-center">
          <Pagination
            total={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable2;

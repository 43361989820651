import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import classes from "./addUserStyle.module.css";
import moment from "moment/moment";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation

const validationRules = {
  emp_code: Yup.string().required("Employee code is required"),
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, "Invalid email format."),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must not exceed 25 characters")
    .required("Password is required"),
  company_id: Yup.string().required("Company is required"),
  role_id: Yup.string().required("Role is required"),
  emp_type: Yup.string().required("Employee type is required"),
  gender: Yup.string(),
  shift_group_code: Yup.string().required("Shift group is required"),
  mobile: Yup.string()
    .required("Primary mobile no. is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
  address: Yup.string().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zip: Yup.string().required("Zip code is required"),
  esi_no: Yup.string()
    .min(17, "Esi number must be of 17 digits")
    .max(17, "Esi number must be of 17 digits"),
  pf_no: Yup.string()
    .min(12, "Pf number must be of 12 digits")
    .max(12, "Pf number must be of 12 digits"),
  ifsc_code: Yup.string()
    .min(11, "Ifsc code must be of 11 digits")
    .max(11, "Ifsc code must be of 11 digits"),
  bank_account_no: Yup.string()
    .min(11, "Account number must be 11 to 16 digits")
    .max(16, "Account number must be 11 to 16 digits"),
};

const AddUser = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [company, setCompany] = useState([]);
  const [contractorDetails, setContractorDetails] = useState([]);
  const [profile, setProfile] = useState(null);
  const [aadhar, setAadhar] = useState(null);
  const [pan, setPan] = useState(null);

  useEffect(() => {
    axiosPrivate
      .get("roles")
      .then((user_roles) => {
        setRoles(user_roles?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          // showErrorToast('Internal server error');
        }
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("company")
      .then((user_company) => {
        setCompany(user_company?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          // showErrorToast('Internal server error');
        }
      });
  }, []);

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`users/${id}`)
        .then((response) => {
          formik.setValues(response?.data?.data);
          formik.setFieldValue("aadhar", response?.data?.data?.uid_no);
          formik.setFieldValue("pan", response?.data?.data?.pan_no);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                formik.setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      emp_code: "",
      name: "",
      email: "",
      password: "",
      company_id: "",
      role_id: "",
      profile: "",
      aadhar: "",
      pan: "",
      grade: "",
      bank_account_no: "",
      department: "",
      designation: "",
      category: "",
      emp_type: "",
      contractor_id: "",
      gender: "",
      doj: "",
      doc: "",
      dob: "",
      dor: "",
      shift_group_code: "",
      mobile: "",
      secondary_mobile: "",
      address: "",
      state: "",
      city: "",
      zip: "",
      esi_applicable: "",
      pf_applicable: "",
      pmgky_applicable: "",
      esi_no: "",
      pf_no: "",
      hra: "",
      other_allowance: "",

      bank_account_no: "",
      ifsc_code: "",
      bank_name: "",
      father_name: "",
      father_aadhar: "",
      father_dob: "",
      mother_name: "",
      mother_aadhar: "",
      mother_dob: "",
      wife_name: "",
      wife_aadhar: "",
      wife_dob: "",
      basic: "",
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values, { setSubmitting, setFieldError }) => {
      let formData = new FormData();
      formData.append("emp_code", values.emp_code);
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("company_id", values.company_id);
      formData.append("role_id", values.role_id);
      formData.append("profile", profile);
      formData.append("aadhar", aadhar);
      formData.append("pan", pan);
      formData.append("grade", values.grade);
      formData.append("basic", values.basic);
      formData.append("department", values.department);
      formData.append("designation", values.designation);
      formData.append("category", values.category);
      formData.append("emp_type", values.emp_type);
      formData.append("contractor_id", values.contractor_id);
      formData.append("gender", values.gender);
      formData.append("doj", moment(values.doj).format("YYYY-MM-DD"));
      formData.append("doc", moment(values.doc).format("YYYY-MM-DD"));
      formData.append("dob", moment(values.dob).format("YYYY-MM-DD"));
      formData.append("dor", moment(values.dor).format("YYYY-MM-DD"));
      formData.append("shift_group_code", values.shift_group_code);
      formData.append("mobile", values.mobile);
      formData.append("secondary_mobile", values.secondary_mobile);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("zip", values.zip);
      formData.append("esi_applicable", values.esi_applicable == true ? 1 : 0);
      formData.append("pf_applicable", values.pf_applicable == true ? 1 : 0);
      formData.append(
        "pmgky_applicable",
        values.pmgky_applicable == true ? 1 : 0
      );
      formData.append("esi_no", values.esi_no);
      formData.append("pf_no", values.pf_no);
      formData.append("hra", values.hra);
      formData.append("other_allowance", values.other_allowance);
      formData.append("bank_account_no", Number(values.bank_account_no));
      formData.append("ifsc_code", values.ifsc_code);
      formData.append("bank_name", values.bank_name);
      formData.append("father_name", values.father_name);
      formData.append("father_aadhar", values.father_aadhar);
      formData.append(
        "father_dob",
        moment(values.father_dob).format("YYYY-MM-DD")
      );
      formData.append("mother_name", values.mother_name);
      formData.append("mother_aadhar", values.mother_aadhar);
      formData.append(
        "mother_dob",
        moment(values.mother_dob).format("YYYY-MM-DD")
      );
      formData.append("wife_name", values.wife_name);
      formData.append("wife_aadhar", values.wife_aadhar);
      formData.append("wife_dob", moment(values.wife_dob).format("YYYY-MM-DD"));

      if (id) {
        axiosPrivate
          .put(`users/${id}`, formData)
          .then((response) => {
            showSuccessToast("User updated Successfully");
            navigate("/admin/user");
          })
          .catch((error) => {
            if (error.response) {
              showErrorToast(error?.response?.data?.errors?.msg);

              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        axiosPrivate
          .post("users", formData)
          .then((response) => {
            showSuccessToast("User created successfully");
            navigate("/admin/user");
          })
          .catch((error) => {
            if (error.response) {
              // showErrorToast('Internal server error');

              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      }

      //   alert(JSON.stringify(values, null, 2));
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const getContractorList = () => {
    axiosPrivate
      .get("contractor")
      .then((contractor) => {
        setContractorDetails(contractor?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          // showErrorToast('Internal server error');
        }
      });
  };

  useEffect(() => {
    if (formik?.values?.emp_type === "day") {
      getContractorList();
    }
  }, [formik?.values?.emp_type]);
  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      User Details :
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* emp_code  */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Employee Code :<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="emp_code"
                            name="emp_code"
                            type="text"
                            className="form-control"
                            placeholder="Enter Employee Code *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.emp_code}
                          />
                          {formik.touched.emp_code && formik.errors.emp_code ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.emp_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* name */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* email */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter Email Id *"
                            // onChange={formik.handleChange}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "email",
                                e.target.value.toLowerCase()
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* password */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Password:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter Password *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Role Id */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Role :<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            id="role_id"
                            name="role_id"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.role_id}
                          >
                            <option
                              value=""
                              label="Select a Role *"
                              disabled
                              selected
                            />
                            {roles.map((role) => (
                              <option
                                key={role.id}
                                value={role.id}
                                label={role.name}
                              />
                            ))}
                          </select>
                          {formik.touched.role_id && formik.errors.role_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.role_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* company ID */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Company:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            id="company_id"
                            name="company_id"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company_id}
                          >
                            <option
                              value=""
                              label="Select a Company *"
                              selected
                              disabled
                            />{" "}
                            {/* Optional default/placeholder option */}
                            {company.map((companyItem) => (
                              <option
                                key={companyItem.id}
                                value={companyItem.id}
                              >
                                {companyItem.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.company_id &&
                          formik.errors.company_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.company_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* primary mobile number */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Primary Mobile Number:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Primary Mobile Number *"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            maxLength={10}
                          />
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* secondary mobile number */}
                      <div className="col-md-4 col-sm-12">
                        <label>Secondary Mobile Number:</label>

                        <div className="form-group">
                          <input
                            id="secondary_mobile"
                            name="secondary_mobile"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Secondary Mobile Number"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_mobile}
                            maxLength={10}
                          />
                          {formik.touched.secondary_mobile &&
                          formik.errors.secondary_mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.secondary_mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* profile */}
                      <div className="col-md-4 col-sm-12">
                        <label>Profile:</label>

                        <div className="form-group">
                          <input
                            id="profile"
                            name="profile"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="profile *"
                            // onChange={(e) => setProfile(e.target.files[0])}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (file && validImageTypes.includes(file.type)) {
                                setProfile(file);
                              } else {
                                // alert("Only JPEG, JPG, and PNG image files are allowed.");
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.values?.profile && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}users/${formik.values.profile}`}
                            alt="Profile Preview"
                            className="preview-image"
                          />
                        )}
                      </div>
                      {/* aadhar */}
                      <div className="col-md-4 col-sm-12">
                        <label>Aadhar:</label>

                        <div className="form-group">
                          <input
                            id="aadhar"
                            name="aadhar"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="Enter Aadhar."
                            // onChange={(e) => setAaadhar(e.target.files[0])}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (file && validImageTypes.includes(file.type)) {
                                setAadhar(file);
                              } else {
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.aadhar &&
													formik.errors.aadhar ? (
														<div className={classes.invalidDataError}>
															{formik.errors.aadhar}
														</div>
													) : null} */}
                        </div>
                        {formik.values?.aadhar && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}users/${formik.values?.aadhar}`}
                            alt="Profile Preview"
                            className="preview-image"
                          />
                        )}
                      </div>
                      {/* pan */}
                      <div className="col-md-4 col-sm-12">
                        <label>PAN:</label>

                        <div className="form-group">
                          <input
                            id="pan"
                            name="pan"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            placeholder="Enter PAN"
                            // onChange={(e) => setPan(e.target.files[0])}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (file && validImageTypes.includes(file.type)) {
                                setPan(file);
                              } else {
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.values?.pan && (
                          <img
                            width={100}
                            height={100}
                            src={`${process.env.REACT_APP_COMMON_IMAGE_URL}users/${formik.values.pan}`}
                            alt="pan Preview"
                            className="preview-image"
                          />
                        )}
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Department:</label>
                        <div className="form-group">
                          <input
                            id="department"
                            name="department"
                            type="text"
                            className="form-control"
                            placeholder="Enter Department"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.department}
                          />
                        </div>
                      </div>

                      {/* designation */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Designation:</label>
                        <div className="form-group">
                          <input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            placeholder="Enter Designation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.designation}
                          />
                        </div>
                      </div>
                      {/* grade */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Grade:</label>
                        <div className="form-group">
                          <input
                            id="grade"
                            name="grade"
                            type="text"
                            className="form-control"
                            placeholder="Enter Grade"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.grade}
                          />
                        </div>
                      </div>

                      {/* category */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Category:</label>
                        <div className="form-group">
                          <input
                            id="category"
                            name="category"
                            type="text"
                            className="form-control"
                            placeholder="Enter Category"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.category}
                          />
                        </div>
                      </div>
                      {/* emp_type */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Employee Type:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="emp_type"
                            name="emp_type"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.emp_type}
                          >
                            <option
                              value=""
                              label="Select an Employee Type *"
                              selected
                              disabled
                            />
                            <option value="monthly" label="Monthly" />
                            <option value="day" label="Daily Wages" />
                          </select>
                          {formik.touched.emp_type && formik.errors.emp_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.emp_type}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {formik?.values?.emp_type === "day" && (
                        <div className="col-md-4 col-sm-12">
                          <label>Contractor :</label>

                          <div className="form-group">
                            <select
                              id="contractor_id"
                              name="contractor_id"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contractor_id}
                            >
                              <option
                                value=""
                                label="Select a Contractor"
                                disabled
                                selected
                              />
                              {contractorDetails.map((contractor) => (
                                <option
                                  key={contractor?.id}
                                  value={contractor?.id}
                                  label={contractor?.name}
                                />
                              ))}
                            </select>
                            {formik.touched.contractor_id &&
                            formik.errors.contractor_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.contractor_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Gender:</label>
                        <div className="form-group d-flex mr-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="genderMale"
                              name="gender"
                              value="male"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={formik.values.gender === "male"}
                            />
                            <label
                              className="form-check-label mr-3"
                              htmlFor="genderMale"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="genderFemale"
                              name="gender"
                              value="female"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={formik.values.gender === "female"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="genderFemale"
                            >
                              Female
                            </label>
                          </div>
                          {formik.touched.gender && formik.errors.gender ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.gender}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* doj */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Date of Joining:</label>
                        <div className="form-group">
                          <input
                            id="doj"
                            name="doj"
                            type="date"
                            className="form-control"
                            placeholder="Date of Joining"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.doj}
                            max="9999-12-31"
                          />
                          {formik.touched.doj && formik.errors.doj ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.doj}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* doc */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Date of Confirmation:</label>
                        <div className="form-group">
                          <input
                            id="doc"
                            name="doc"
                            type="date"
                            className="form-control"
                            placeholder="Date of Confirmation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.doc}
                            max="9999-12-31"
                          />
                          {formik.touched.doc && formik.errors.doc ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.doc}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* dob */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Date of Birth:</label>
                        <div className="form-group">
                          <input
                            id="dob"
                            name="dob"
                            type="date"
                            className="form-control"
                            placeholder="Date of Birth"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.dob}
                            max="9999-12-31"
                          />
                          {formik.touched.dob && formik.errors.dob ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.dob}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* dor */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Release Date:</label>
                        <div className="form-group">
                          <input
                            id="dor"
                            name="dor"
                            type="date"
                            className="form-control"
                            placeholder="Date of Retirement"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.dor}
                            max="9999-12-31"
                          />
                          {formik.touched.dor && formik.errors.dor ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.dor}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Shift Timing:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="shift_group_code"
                            name="shift_group_code"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shift_group_code}
                          >
                            <option
                              value=""
                              label="Select a Shift Group *"
                              selected
                              disabled
                            />
                            <option value="6-2" label="06:00am - 02:00pm" />
                            <option value="2-10" label="02:00pm - 10:00pm" />
                            <option value="10-6" label="10:00pm - 06:00am" />
                          </select>
                          {formik.touched.shift_group_code &&
                          formik.errors.shift_group_code ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.shift_group_code}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* address */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Address:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="Enter Address *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <label>
                          State:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="state"
                            name="state"
                            placeholder="Enter State *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <label>
                          City:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Enter City *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Pincode:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Enter Pincode *"
                            name="zip"
                            id="zip"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.zip}
                            maxLength={6}
                          />
                          {formik.touched.zip && formik.errors.zip ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.zip}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>ESI Applicable:</label>
                        <div className="form-group">
                          <input
                            id="esi_applicable"
                            name="esi_applicable"
                            type="checkbox"
                            checked={formik.values.esi_applicable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.esi_applicable}
                          />
                          {formik.touched.esi_applicable &&
                          formik.errors.esi_applicable ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.esi_applicable}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* pf_applicable */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>PF Applicable:</label>
                        <div className="form-group">
                          <input
                            id="pf_applicable"
                            name="pf_applicable"
                            type="checkbox"
                            checked={formik.values.pf_applicable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pf_applicable}
                          />
                          {formik.touched.pf_applicable &&
                          formik.errors.pf_applicable ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pf_applicable}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* pmgky_applicable */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>PMGKY Applicable:</label>
                        <div className="form-group">
                          <input
                            id="pmgky_applicable"
                            name="pmgky_applicable"
                            type="checkbox"
                            checked={formik.values.pmgky_applicable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pmgky_applicable}
                          />
                          {formik.touched.pmgky_applicable &&
                          formik.errors.pmgky_applicable ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pmgky_applicable}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* esi_no */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>ESI Number:</label>
                        <div className="form-group">
                          <input
                            id="esi_no"
                            name="esi_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter ESI Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.esi_no}
                            maxLength={17}
                          />
                          {formik.touched.esi_no && formik.errors.esi_no ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.esi_no}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* pf_no */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>PF Number:</label>
                        <div className="form-group">
                          <input
                            id="pf_no"
                            name="pf_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter PF Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pf_no}
                            maxLength={12}
                          />
                          {formik.touched.pf_no && formik.errors.pf_no ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.pf_no}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* basic */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Basic Salary:</label>
                        <div className="form-group">
                          <input
                            id="basic"
                            name="basic"
                            type="text"
                            className="form-control"
                            placeholder="Enter Basic Salary"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.basic}
                            maxLength={6}
                          />
                          {formik.touched.basic && formik.errors.basic ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.basic}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* hra */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>HRA:</label>
                        <div className="form-group">
                          <input
                            id="hra"
                            name="hra"
                            type="text"
                            className="form-control"
                            placeholder="Enter HRA"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.hra}
                            maxLength={6}
                          />
                          {formik.touched.hra && formik.errors.hra ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.hra}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* other_allowance */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>Other Allowance:</label>
                        <div className="form-group">
                          <input
                            id="other_allowance"
                            name="other_allowance"
                            type="number"
                            className="form-control"
                            placeholder="Enter Other Allowance"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.other_allowance}
                          />
                          {formik.touched.other_allowance &&
                          formik.errors.other_allowance ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.other_allowance}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12"></div>
                      {formik?.values?.emp_type !== "day" && (
                        <div className="col-md-4 col-sm-12"></div>
                      )}
                      <div className="col-md-4 col-sm-12">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          Bank Details :
                        </strong>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12"></div>
                      {/* bank_account_no */}

                      <div className="col-md-4 col-sm-12">
                        <label>Bank Account No. :</label>

                        <div className="form-group">
                          <input
                            id="bank_account_no"
                            name="bank_account_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter Bank Account No."
                            onChange={(e) =>{
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.bank_account_no}
                            maxLength={16}
                          />
                          {/* {formik.touched.bank_account_no &&
														formik.errors.bank_account_no ? (
															<div className={classes.invalidDataError}>
																{formik.errors.bank_account_no}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* ifsc_code */}
                      <div className="col-md-4 col-sm-12">
                        <label>Bank IFSC Code :</label>

                        <div className="form-group">
                          <input
                            id="ifsc_code"
                            name="ifsc_code"
                            type="text"
                            className="form-control"
                            placeholder="Enter Bank IFSC Code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ifsc_code}
                            maxLength={11}
                          />
                          {formik.touched.ifsc_code &&
                          formik.errors.ifsc_code ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.ifsc_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* bank_name */}
                      <div className="col-md-4 col-sm-12">
                        <label>Bank Name :</label>

                        <div className="form-group">
                          <input
                            id="bank_name"
                            name="bank_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Bank Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bank_name}
                          />
                          {/* {formik.touched.bank_name &&
														formik.errors.bank_name ? (
															<div className={classes.invalidDataError}>
																{formik.errors.bank_name}
															</div>
														) : null} */}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          Father Details :
                        </strong>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12"></div>
                      {/* father details */}

                      <div className="col-md-4 col-sm-12">
                        <label>Father Name :</label>

                        <div className="form-group">
                          <input
                            id="father_name"
                            name="father_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Father Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.father_name}
                          />
                          {/* {formik.touched.father_name &&
														formik.errors.father_name ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_name}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* father addhar details  */}
                      <div className="col-md-4 col-sm-12">
                        <label>Father aadhar Details :</label>

                        <div className="form-group">
                          <input
                            id="father_aadhar"
                            name="father_aadhar"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Father Aadhar"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.father_aadhar}
                            maxLength={12}
                          />
                          {/* {formik.touched.father_aadhar &&
														formik.errors.father_aadhar ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_aadhar}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* father date of birth */}
                      <div className="col-md-4 col-sm-12">
                        <label>Father Date Of Birth Details :</label>

                        <div className="form-group">
                          <input
                            id="father_dob"
                            name="father_dob"
                            type="date"
                            className="form-control"
                            placeholder="Enter Father DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.father_dob}
                            max="9999-12-31"
                          />
                          {/* {formik.touched.father_dob &&
														formik.errors.father_dob ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_dob}
															</div>
														) : null} */}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          Mother Details :
                        </strong>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12"></div>
                      {/* mother details */}
                      <div className="col-md-4 col-sm-12">
                        <label>Mother Name :</label>

                        <div className="form-group">
                          <input
                            id="mother_name"
                            name="mother_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Mother Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mother_name}
                          />
                          {/* {formik.touched.father_name &&
														formik.errors.father_name ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_name}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* father addhar details  */}
                      <div className="col-md-4 col-sm-12">
                        <label>Mother aadhar Details :</label>

                        <div className="form-group">
                          <input
                            id="mother_aadhar"
                            name="mother_aadhar"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Mother Aadhar"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mother_aadhar}
                            maxLength={12}
                          />
                          {/* {formik.touched.father_aadhar &&
														formik.errors.father_aadhar ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_aadhar}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* father date of birth */}
                      <div className="col-md-4 col-sm-12">
                        <label>Mother Date Of Birth Details :</label>

                        <div className="form-group">
                          <input
                            id="mother_dob"
                            name="mother_dob"
                            type="date"
                            className="form-control"
                            placeholder="Enter mother DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mother_dob}
                            max="9999-12-31"
                          />
                          {/* {formik.touched.father_dob &&
														formik.errors.father_dob ? (
															<div className={classes.invalidDataError}>
																{formik.errors.father_dob}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* wife details */}
                      <div className="col-md-4 col-sm-12">
                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                          Wife Details :
                        </strong>
                      </div>
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12"></div>

                      <div className="col-md-4 col-sm-12">
                        <label>Wife Name :</label>

                        <div className="form-group">
                          <input
                            id="wife_name"
                            name="wife_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Wife Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.wife_name}
                          />
                          {/* {formik.touched.wife_name &&
														formik.errors.wife_name ? (
															<div className={classes.invalidDataError}>
																{formik.errors.wife_name}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* wife addhar details  */}
                      <div className="col-md-4 col-sm-12">
                        <label>Wife aadhar Details :</label>

                        <div className="form-group">
                          <input
                            id="wife_aadhar"
                            name="wife_aadhar"
                            type="tel"
                            className="form-control"
                            placeholder="Enter Wife Aadhar"
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.wife_aadhar}
                            maxLength={12}
                          />
                          {/* {formik.touched.wife_aadhar &&
														formik.errors.wife_aadhar ? (
															<div className={classes.invalidDataError}>
																{formik.errors.wife_aadhar}
															</div>
														) : null} */}
                        </div>
                      </div>
                      {/* wife date of birth */}
                      <div className="col-md-4 col-sm-12">
                        <label>Wife Date Of Birth Details :</label>

                        <div className="form-group">
                          <input
                            id="wife_dob"
                            name="wife_dob"
                            type="date"
                            className="form-control"
                            placeholder="Enter Wife DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.wife_dob}
                            max="9999-12-31"
                          />
                          {/* {formik.touched.wife_dob &&
														formik.errors.wife_dob ? (
															<div className={classes.invalidDataError}>
																{formik.errors.wife_dob}
															</div>
														) : null} */}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/user")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            {id ? "Update" : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;

import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FiUsers, FiBox } from "react-icons/fi";
import { CgUserList } from "react-icons/cg";
import { GoTasklist } from "react-icons/go";
import { TbReport } from "react-icons/tb";
import { MdOutlineHolidayVillage, MdOutlinePayments } from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";
import { GiInterleavedClaws, GiFireFlower } from "react-icons/gi";
import AuthContext from "../../context/auth/AuthContext";

const SideBar = () => {
  const { authPermissions } = useContext(AuthContext);
  const [isMasterOpen, setMasterOpen] = useState(false); // State to manage the Master dropdown

  // Function to toggle Master dropdown
  const toggleMasterDropdown = () => {
    setMasterOpen(!isMasterOpen);
  };

  return (
    <Fragment>
      {/* <div id="header_top" className="header_top false">
        <div className="container">
        <div className="hleft">
            <Link to="/">
              <a aria-current="page" className="header-brand active">
                <img src="" />
              </a>
            </Link>
          </div>
          <div className="hright">
            <div className="dropdown">
                <p className="nav-link user_btn">
                  <img
                    className="avatar"
                    src={require("../../assets/images/user.png")}
                    alt="User Menu"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="User Menu"
                  />
                </p>
            </div>
          </div>
        </div>
      </div> */}

      <div id="left-sidebar" className="sidebar">
        <h5 className="brand-name">{process.env.REACT_APP_APP_NAME}</h5>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <ul className="metismenu">
            <li>
              <span className="g_heading">Directions</span>
            </li>

            {/* Master Dropdown */}
            <li>
              <span className="list-a" onClick={toggleMasterDropdown} style={{ cursor: "pointer" }}>
                Master
                <span className="float-left">
                  {isMasterOpen ? "▼" : "►"} {/* Change arrow based on state */}
                </span>
              </span>
              {/* Dropdown menu */}
              {isMasterOpen && (
                <ul>
                  <li>
                    <Link className="list-a" to="/admin/user">
                      <FiUsers className="mr-2" size="16" />
                      Users
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/contractor">
                      <FiUsers className="mr-2" size="16" />
                      Contractors
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/roles">
                      <CgUserList className="mr-2" size="16" />
                      Roles & Permissions
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/esi">
                      <FiBox className="mr-2" size="16" />
                      ESI
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/pf">
                      <GoTasklist className="mr-2" size="16" />
                      PF List
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/companyform">
                      <TbReport className="mr-2" size="16" />
                      Company
                    </Link>
                  </li>
                  <li>
                    <Link className="list-a" to="/admin/holiday">
                      <MdOutlineHolidayVillage className="mr-2" size="16" />
                      Holiday
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Other menu items */}
            <li>
              <Link className="list-a" to="/admin/attendance">
                <BsCheck2Circle className="mr-2" size="16" />
                Attendance
              </Link>
            </li>
            <li>
              <Link className="list-a" to="/admin/regularization">
                <GiFireFlower className="mr-2" size="16" />
                Regularization
              </Link>
            </li>
            <li>
              <Link className="list-a" to="/admin/leave">
                <GiInterleavedClaws className="mr-2" size="16" />
                Leave
              </Link>
            </li>
            <li>
              <Link className="list-a" to="/admin/payroll">
                <MdOutlinePayments className="mr-2" size="16" />
                Payroll
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  );
};

export default SideBar;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import { useNavigate } from "react-router-dom/dist";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toaster";

function LeaveForm() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required("Employee name is required"),
    from_date: Yup.date().required("From Date is required"),
    to_date: Yup.date().required("To Date is required"),
    leave_duration: Yup.string().required("Leave Duration is required"),
    reason: Yup.string().required("Reason is required"),
  });

  useEffect(() => {
    axiosPrivate
      .get("users")
      .then((res) => setUsers(res?.data?.data))
      .catch((error) => console.log(error));
  }, []);

  const setToDate=(e)=>{
    // setFieldValue('to_date' , e.target.value)
    values.to_date = e.target.value;
    handleChange(e);
  }

  const { values, errors, handleSubmit, handleBlur, handleChange, touched , setFieldValue} =
    useFormik({
      initialValues: {
        user_id: "",
        from_date: "",
        to_date: "",
        leave_duration: "",
        reason: "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        axiosPrivate
          .post("leaves", values)
          .then((res) => {
            navigate("/admin/leave");
            showSuccessToast("Leave created successfully");
          })
          .catch((error) => showErrorToast(error));
      },
    });

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Add Leave Details
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          Employee Name
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="user_id"
                            name="user_id"
                            className="form-control"
                            value={values.user_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="" disabled selected>
                              Select Employee
                            </option>
                            {users.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                          {errors.user_id && touched.user_id && (
                            <p style={{ color: "red", fontSize: "smaller" }}>
                              {errors.user_id}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* date from */}

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          From Date:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="from_date"
                            name="from_date"
                            type="date"
                            className="form-control"
                            placeholder="From Date "
                            onChange={e=>setToDate(e)}
                            onBlur={handleBlur}
                            value={values.from_date || new Date().toISOString().split('T')[0]}
                          />
                          {errors.from_date && touched.from_date && (
                            <p style={{ color: "red", fontSize: "smaller" }}>
                              {errors.from_date}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* date to */}

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          To Date:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="to_date"
                            name="to_date"
                            type="date"
                            className="form-control"
                            placeholder="to_date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.to_date || new Date().toISOString().split('T')[0]}
                          />
                          {errors.to_date && touched.to_date && (
                            <p style={{ color: "red", fontSize: "smaller" }}>
                              {errors.to_date}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          Leave Duration
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="leave_duration"
                            name="leave_duration"
                            className="form-control"
                            value={values.leave_duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="" disabled>
                              Select Leave Duration
                            </option>
                            <option value="first-half">First Half</option>
                            <option value="second-half">Second Half</option>
                            <option value="full-day">Full Day</option>
                          </select>
                          {errors.leave_duration && touched.leave_duration && (
                            <p style={{ color: "red", fontSize: "smaller" }}>
                              {errors.leave_duration}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          Reason<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="reason"
                            name="reason"
                            type="text"
                            className="form-control"
                            placeholder="Type reason here"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.reason}
                          />
                          {errors.reason && touched.reason && (
                            <p style={{ color: "red", fontSize: "smaller" }}>
                              {errors.reason}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/leave")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveForm;

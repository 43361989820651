import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import classes from "../companyForm/addcompanyForm.module.css";
import axiosApiInstance from "../../../utils/http/axiosPrivate";
import { useNavigate } from "react-router-dom";

function Attendance() {
    const navigate = useNavigate();

    const [attendanceFile, setAttendanceFile] = useState(null);
    const [error, setError] = useState('');

    const fileRef = useRef();

    const submitAttendance = () => {
        if (attendanceFile) {
            setError('');
            let formData = new FormData();
            formData.append('file', attendanceFile);
            axiosApiInstance.post('attendance-details', formData).then((res) => {
                setAttendanceFile(null);
                navigate('/admin/attendance')
                if (fileRef.current) {
                    fileRef.current.value = '';
                }
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            setError('File is Required')
        }
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <form>
                            <div
                                className="tab-pane fade show active"
                                id="user-add"
                                role="tabpanel"
                            >
                                <div className="card">
                                    <div className="card-header">
                                        <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                                            Upload Attendance Sheet
                                        </strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Attendance Sheet:<span className="required">*</span>
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        id="file"
                                                        name="file"
                                                        ref={fileRef}
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => { setAttendanceFile(e.target.files[0]); setError('') }}
                                                    />
                                                    <div className={classes.invalidDataError}>
                                                        {error}
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div className="col-md-12 col-sm-12 submit-btn">
                                                <div className="form-group">
                                                <button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
														onClick={() => navigate('/admin/attendance')}
													>
														Close
													</button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary ml-2"
                                                        onClick={() => submitAttendance()}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Attendance;

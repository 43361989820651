import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "../companyForm/addcompanyForm.module.css";
import axiosApiInstance from "../../../utils/http/axiosPrivate";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import { showErrorToast } from "../../../Toaster";
import styles from "./payroll.module.css"
function Payroll() {
  const [company, setCompany] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const validationRules = {
    
    company_id: Yup.number().required("Company name is required"),
  };

  useEffect(() => {
    axiosPrivate
      .get("company")
      .then((res) => {
        setCompany(res?.data?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const initialValues = {
    company_id: "",
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values) => {
      axiosApiInstance
        .get(`payroll/${month}/${year}/${values?.company_id}`)
        .then((res) => {
          let data = res?.data?.data;
          for (const value of data) {
            delete value?.user_id;
          }
          downloadPayroll(data);
          handleReset();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const downloadPayroll = (data) => {
    if (data?.length > 0) {
      import("xlsx").then((xlsx) => {
        const modifiedData = data.map((item) => {
          const {
            user_id,
            user_emp_code,
            esi_employee_percent,
            esi_employer_percent,
            esi_employee_fixed_amount,
            esi_employer_fixed_amount,
            pf_employee_percent,
            pf_employer_percent,
            pf_employee_fixed_amount,
            pf_employer_fixed_amount,
            ...rest
          } = item;

          // Map "esi_applicable" and "pf_applicable" values to "yes" or "no"
          const esiApplicable = item.esi_applicable === 1 ? "yes" : "no";
          const pfApplicable = item.pf_applicable === 1 ? "yes" : "no";

          // Return an array of values, including modified values for ESI and PF applicability
          return [
            rest.name,
            rest.wages_type,
            esiApplicable,
            pfApplicable,
            rest.pmkgy_applicable,
            rest.wages,
            rest.wages_earn,
            rest.total_days,
            rest.absent,
            rest.present,
            rest.basic_salary,
            rest.hra,
            rest.other_allowance,
            rest.total_earnings,
            rest.esi_employee,
            rest.pf_employee,
            rest.esi_employer,
            rest.pf_employer,
            rest.total_employee,
            rest.total_employer,
            rest.gross_salary,
            rest.salary_payable,
            rest.net_payable,
            rest.company_name,
          ];
        });

        const modifiedHeader = [
          "Name",
          "Wages Type",
          "ESI Applicable",
          "PF Applicable",
          "PMKGY Applicable",
          "Wages",
          "Wages Earn",
          "Total Days",
          "Absent",
          "Present",
          "Basic Salary",
          "HRA",
          "Other Allowance",
          "Total Earnings",
          "ESI Employee",
          "PF Employee",
          "ESI Employer",
          "PF Employer",
          "Total Employee",
          "Total Employer",
          "Gross Salary",
          "Salary Payable",
          "Net Payable",
          "Company Name",
        ];

        const boldStyle = { bold: true };
        const worksheet = xlsx.utils.aoa_to_sheet([modifiedHeader], {
          header: boldStyle,
        });
        xlsx.utils.sheet_add_aoa(worksheet, modifiedData, { origin: -1 });

        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Placeholder function for saving the Excel file
        const saveAsExcelFile = (buffer, fileName) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName + ".xlsx";
          link.click();
        };

        saveAsExcelFile(excelBuffer, "Payroll Report");
      });
    } else {
      showErrorToast("No data found!.");
    }
  };

  useEffect(() => {
    // Get the current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    // Set the state for year and month
    setYear(currentYear);
    setMonth(currentMonth);
  }, []);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <li className="nav-item navSpace mb-3">
              <div className="card-header headerSpace">
                <strong className="top-left-heading">PAYROLL</strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="route-heading item mx-2">Dashboard</li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">Payroll</li>
                </ol>
              </div>
            </li>
            <form onSubmit={handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          Payroll Month / Year:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="month"
                            id="month"
                            name="month"
                            className={styles.dateContainer}
                            onChange={(e) => {
                              const year = e.target.value.split("-")[0];
                              const month = e.target.value.split("-")[1];
                              setYear(year);
                              setMonth(month);
                            }}
                            value={`${year}-${month}`}
                          ></input>
                          
                        </div>
                      </div>
                      
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <label>
                          Company Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="company_id"
                            name="company_id"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company_id}
                          >
                            <option
                              value=""
                              selected
                              label="Select Company"
                              disabled
                            />
                            {company.map((res) => (
                              <option value={res.id} label={res.name} />
                            ))}
                          </select>
                          {touched.company_id && errors.company_id ? (
                            <div className={classes.invalidDataError}>
                              {errors.company_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 submit-btn">
                      
                      <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Generate Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payroll;
